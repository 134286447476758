
import {
  AddFavoriteCommand,
  CollapseNodeCommand,
  DeselectCommand,
  ExpandNodeCommand,
  RemoveFavoriteCommand,
  SelectCommand,
} from '@/classifications/api/runtime/CommandExecutor';
import PIS from '@/common/api/PIS';
import Icon from '@/common/components/Icon.vue';
import {
  ClassificationsBrowserNode,
  ClassificationsBrowserTreeViewEnum,
  ProductGroupValidationStatusEnum,
} from '@/common/services/swagger/index.defs';
import { PropType, computed, defineComponent } from 'vue';
import Tooltip from '@/common/components/Tooltip.vue';
import { TranslateFunc } from '@/classifications/composables/setupComponent';
import TransitionHeight from './TransitionHeight.vue';

export default defineComponent({
  name: 'ClassificationNode',
  components: { Icon, Tooltip, TransitionHeight },
  props: {
    item: { type: Object as () => ClassificationsBrowserNode, required: true },
    t: { type: Function as PropType<TranslateFunc>, required: true },
    instanceId: String,
  },

  setup(props) {
    const isExpanded = computed(() => props.item['_isExpanded']);
    const isLoading = computed(() => props.item['_isLoading']);
    const instance = computed(() => PIS.Classifications.getInstance(props.instanceId));

    const selection = computed(() => instance.value?.selection);
    const favorites = computed(() => instance.value?.favorites);
    const isSelected = computed(() => selection.value?.isSelected(props.item));
    const isFavorite = computed(() => favorites.value?.isSelected(props.item));
    const areFavoritesEnabled = computed<boolean>(() =>
      (instance.value?.getOptions().components?.treeTypeTabs?.displayModes || []).includes(
        ClassificationsBrowserTreeViewEnum.Favorites,
      ),
    );

    const isInvalidPg = computed(
      () => props.item.productGroup?.status === ProductGroupValidationStatusEnum.Invalid,
    );

    const isValidPg = computed(
      () => props.item.productGroup?.status === ProductGroupValidationStatusEnum.Valid,
    );

    const isLeaf = computed(() =>
      instance.value?.getRouteData()?.view === ClassificationsBrowserTreeViewEnum.Favorites
        ? !props.item.children
        : props.item.leaf,
    );

    const toggleNode = async () => {
      if (props.item.leaf) {
        return;
      }
      try {
        if (isExpanded.value) {
          await instance.value?.execute(new CollapseNodeCommand({ item: props.item }));
        } else {
          await instance.value?.execute(new ExpandNodeCommand({ item: props.item }));
        }
      } catch (error) {
        // Ignore error
      }
    };

    const toggleSelection = async () => {
      try {
        if (selection.value?.isSelected(props.item)) {
          await instance.value?.execute(new DeselectCommand({ item: props.item }));
        } else {
          await instance.value?.execute(new SelectCommand({ item: props.item }));
        }
      } catch (error) {
        // Ignore error
      }
    };

    const toggleFavorite = async () => {
      try {
        if (favorites.value?.isSelected(props.item)) {
          await instance.value?.execute(new RemoveFavoriteCommand({ item: props.item }));
        } else {
          await instance.value?.execute(new AddFavoriteCommand({ item: props.item }));
        }
      } catch (error) {
        // Ignore error
      }
    };

    return {
      isExpanded,
      isLoading,
      isSelected,
      isFavorite,
      isInvalidPg,
      isValidPg,
      selection,
      areFavoritesEnabled,
      isLeaf,
      toggleNode,
      toggleSelection,
      toggleFavorite,
    };
  },
});
